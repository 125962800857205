<template>
  <div class="main">
    <el-form label-position="left" :model="verifyloginForm" v-if="!success">
      <el-form-item label="用户名">
        <el-input
          v-model="verifyloginForm.userName"
          placeholder="请输入用户名"
        ></el-input>
      </el-form-item>
      <el-form-item label="密码">
        <el-input type="password" v-model="verifyloginForm.password" placeholder="请输入密码">
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">立即绑定</el-button>
      </el-form-item>
    </el-form>
    <div v-else style="text-align: center; margin-top: 100px">
      <i class="el-icon-success" style="color: green; font-size:68px"></i>
      <p>已成功绑定</p>
    </div>
  </div>
</template>

<script>
import { wechatBindSystem } from "@/api/user";
export default {
  data() {
    return {
      verifyloginForm: {
        userName: "",
        password: "",
      },
      isDisabled: false,
      buttonName: "获取验证码",
      second: 60,
      query: {},
      success: false
    };
  },
  mounted() {
    this.query = this.$route.query;
    if (this.query.type == "03") {
      this.$message({
        type: 'error',
        message: '获取微信信息错误，请关闭页面重试！'
      })
    } else if(this.query.type == "02") {
      this.success = true;
    }
  },
  methods: {
    // 倒计时
    run() {
      let that = this;
      that.isDisabled = true;
      let interval = window.setInterval(function () {
        that.buttonName = "(" + that.second + "秒)后重新发送";
        --that.second;
        if (that.second < 0) {
          that.buttonName = "重新发送";
          that.second = 60;
          that.isDisabled = false;
          window.clearInterval(interval);
        }
      }, 1000);
    },
    // 公众号绑定
    wechatBindSystem(data) {
      wechatBindSystem(data).then(res => {
        if (res.success) {
          this.success = true;
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          })
        }
      })
    },
    onSubmit() {
      if (this.verifyloginForm.userName == "") {
        this.$message.error("请输入用户名！");
        return false;
      }
      if (this.verifyloginForm.password == "") {
        this.$message.error("请输入密码！");
        return false;
      }
      this.wechatBindSystem({
        ...this.verifyloginForm,
        ticket: this.query.ticket
      })
    },
  },
};
</script>

<style lang="scss" scope>
.main {
  padding: 20px;
}
</style>